<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="handleSubmit">
      <b-card class="contact_form">
        <b-card-body class="py-0">
          <p>
            <small>All fields with <span class="text-danger">*</span> are required.</small>
          </p>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }" mode="passive">
                  <label for="contactName">Name:</label><span class="ml-1 text-danger">*</span>
                  <input name="contactName" v-model="contact.name" class="form-control" type="text" />
                  <span class="text-danger form-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }" mode="passive">
                  <label for="contactEmail">Email:</label><span class="ml-1 text-danger">*</span>
                  <input name="contactEmail" v-model="contact.email" class="form-control" type="email" />
                  <span class="text-danger form-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="contactSubject">Subject:</label>
                <input name="contactSubject" v-model="contact.subject" class="form-control" type="text" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <ValidationProvider name="Message" rules="required" v-slot="{ errors }" mode="passive">
                  <label for="contactMessage">Message:</label><span class="ml-1 text-danger">*</span>
                  <textarea name="contactMessage" v-model="contact.message" class="form-control" rows="4" />
                  <span class="text-danger form-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group form-check-inline">
                <ValidationProvider name="Consent" :rules="{required: {allowFalse: false}}" v-slot="{ errors }" mode="passive">
                  <input name="contactConsent" v-model="contact.consent" class="form-check-input mr-2" type="checkbox" />
                  <label for="contactConsent" class="form-check-label d-inline">I confirm that I have read and understood the <router-link :to="{name:'policy'}">Privacy Policy</router-link>.</label><span class="ml-1 text-danger">*</span>
                  <span class="text-danger form-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <b-alert class="my-2" :variant="respIsSuccess ? 'success' : 'danger'" show v-if="feedback">{{feedback}}</b-alert>
        </b-card-body>
        <template v-slot:footer>
          <input v-model="contact.karaspace" name="karaspace" value="" placeholder="Keep this field empty" v-show="false" />
          <button class="btn btn-primary mr-3" type="submit" :disabled="submitting">
            <b-spinner small v-if="submitting" />
            Send Message
          </button>
          <button class="btn btn-danger" type="reset" :disabled="submitting" @click.prevent="reset">Reset</button>
        </template>
      </b-card>
      <div class="text-muted mt-3">
        <p>
          <small>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</small>
        </p>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/util/validation';
import HttpRequest from '@/util/HttpRequest';

const RECAPTCHA_SITE_KEY = '6Lcm78YZAAAAAOkRToYu73Og5NcneHYR-8aXXWMU';
const CONTACT_FORM_BACKEND_URL = 'https://script.google.com/macros/s/AKfycbzBJJiL-7PAiqIexuf5g0Lv-mpfJCDV9K-njw5tehFi7WnxpPmU/exec';

export default {
  name: 'ContactForm',
  components: { ValidationProvider, ValidationObserver },

  data() {
    return {
      contact: {},
      submitting: false,
      feedback: '',
      respIsSuccess: false,
    };
  },

  computed: {
  },

  methods: {
    handleSubmit() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return;

        const contact = { ...this.contact };
        // Backend needs 'karaspace' field to be empty
        if (!contact.karaspace) contact.karaspace = '';
        // set recaptcha token
        contact.recaptcha = await this.getRecaptchaToken();

        this.submitting = true;
        HttpRequest.post(CONTACT_FORM_BACKEND_URL, JSON.stringify(contact))
          .then(({ data }) => {
            if (data.success) {
              this.reset();
              this.respIsSuccess = true;
              this.feedback = 'Thanks a lot for your message.';
            } else {
              this.respIsSuccess = false;
              this.feedback = data.message;
            }
          })
          .catch(() => {
            this.$toast.error('Message submission failed. Please, try again later.');
          })
          .finally(() => {
            this.submitting = false;
          });
      });
    },
    loadRecaptchaJS() {
      const scriptId = 'RECAPTCHA_SCRIPT_ID';
      if (document.getElementById(scriptId)) return;

      const js = document.createElement('script');
      js.id = scriptId;
      js.type = 'text/javascript';
      js.src = `https://www.recaptcha.net/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      document.body.appendChild(js);
    },
    getRecaptchaToken() {
      return new Promise((resolve) => {
        // disable eslint since grecaptcha is not defined here. It is defined within the recaptcha script loaded above.
        /* eslint-disable no-undef */
        grecaptcha.ready(() => {
          grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'homepage' })
            .then(token => resolve(token))
            .catch(() => { /* ignore */ });
        });
        /* eslint-enable no-undef */
      });
    },
    reset() {
      this.$refs.form.reset();
      this.contact = Object.assign({});
      this.feedback = '';
    },
  },

  mounted() {
    this.reset();
    this.$nextTick(() => { this.loadRecaptchaJS(); });
  },
};
</script>

<style lang="stylus" scoped>
.contact_form
  .form-group
    margin-bottom 0.5rem
    label
      margin-bottom 0

</style>

<style lang="stylus">

.grecaptcha-badge
  visibility: hidden
  display: none

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-card',{staticClass:"contact_form",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.karaspace),expression:"contact.karaspace"},{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"name":"karaspace","value":"","placeholder":"Keep this field empty"},domProps:{"value":(_vm.contact.karaspace)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "karaspace", $event.target.value)}}}),_c('button',{staticClass:"btn btn-primary mr-3",attrs:{"type":"submit","disabled":_vm.submitting}},[(_vm.submitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Send Message ")],1),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"reset","disabled":_vm.submitting},on:{"click":function($event){$event.preventDefault();return _vm.reset($event)}}},[_vm._v("Reset")])]},proxy:true}])},[_c('b-card-body',{staticClass:"py-0"},[_c('p',[_c('small',[_vm._v("All fields with "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" are required.")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contactName"}},[_vm._v("Name:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.name),expression:"contact.name"}],staticClass:"form-control",attrs:{"name":"contactName","type":"text"},domProps:{"value":(_vm.contact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contactEmail"}},[_vm._v("Email:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.email),expression:"contact.email"}],staticClass:"form-control",attrs:{"name":"contactEmail","type":"email"},domProps:{"value":(_vm.contact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contactSubject"}},[_vm._v("Subject:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.subject),expression:"contact.subject"}],staticClass:"form-control",attrs:{"name":"contactSubject","type":"text"},domProps:{"value":(_vm.contact.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "subject", $event.target.value)}}})])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Message","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contactMessage"}},[_vm._v("Message:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.message),expression:"contact.message"}],staticClass:"form-control",attrs:{"name":"contactMessage","rows":"4"},domProps:{"value":(_vm.contact.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "message", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group form-check-inline"},[_c('ValidationProvider',{attrs:{"name":"Consent","rules":{required: {allowFalse: false}},"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.consent),expression:"contact.consent"}],staticClass:"form-check-input mr-2",attrs:{"name":"contactConsent","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.contact.consent)?_vm._i(_vm.contact.consent,null)>-1:(_vm.contact.consent)},on:{"change":function($event){var $$a=_vm.contact.consent,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.contact, "consent", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.contact, "consent", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.contact, "consent", $$c)}}}}),_c('label',{staticClass:"form-check-label d-inline",attrs:{"for":"contactConsent"}},[_vm._v("I confirm that I have read and understood the "),_c('router-link',{attrs:{"to":{name:'policy'}}},[_vm._v("Privacy Policy")]),_vm._v(".")],1),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),(_vm.feedback)?_c('b-alert',{staticClass:"my-2",attrs:{"variant":_vm.respIsSuccess ? 'success' : 'danger',"show":""}},[_vm._v(_vm._s(_vm.feedback))]):_vm._e()],1)],1),_c('div',{staticClass:"text-muted mt-3"},[_c('p',[_c('small',[_vm._v("This site is protected by reCAPTCHA and the Google "),_c('a',{attrs:{"href":"https://policies.google.com/privacy","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://policies.google.com/terms","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Terms of Service")]),_vm._v(" apply.")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }